















































import Vue from 'vue'
import { LIST_PARTICIPANT_COURSES_WIDGET } from '../graphql'
import RunTile from './RunTile.vue'
import { Query, RunDashboard } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'ParticipantRunsWidget',

  components: { RunTile },

  data () {
    return {
      runs: null! as RunDashboard[],
      loading: 0
    }
  },

  computed: {
    attendingCourses (): any[] {
      if (!this.runs) {
        return []
      }
      return this.runs.map(run => ({
        ...run.run,
        configuration: run.configuration
      }))
    }
  },

  watch: {
    loading (v: number, o: number) {
      this.$emit('loading', v - o)
    }
  },

  apollo: {
    runs: {
      query: LIST_PARTICIPANT_COURSES_WIDGET,

      fetchPolicy: 'cache-and-network',

      update (result: Query) {
        const { courses } = result.participantDashboard

        return courses?.attending || []
        // Object.keys(courses).reduce((acc, key) => acc.concat(Array.isArray(courses[key]) ? courses[key] : []), [])
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})
